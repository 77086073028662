.Chip {
  background: $light-grey;
  font-size: 8px;
  padding: 2px 5px;
  border-radius: $border-radius-s;
  text-transform: uppercase;
}

.isChipActive {
  background: $primary-light-gradien !important;
  color: white;
}
