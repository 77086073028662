.Footer {
  width: $full-width;
  border-top: 1px solid #e5e5e5;
  background-color: $white;
  position: sticky;
  bottom: 0;

  .footerContent {
    padding: 20px;
  }
}
