.Basket {
  width: $full-width;
  display: flex;
  flex-direction: column;

  .offerChoosen {
    width: $full-width;
    display: flex;
    flex-direction: column;

    .Accordion {
      width: $full-width;
      box-shadow: none;
      border: none;
      background-color: transparent;
      display: flex;
      flex-direction: column;

      .MuiAccordionDetails-root {
        background-color: transparent;
        padding: 0;
      }

      .MuiAccordionSummary-root {
        display: flex;
        align-self: center;
        justify-content: center;
        min-height: 0;
        padding: 0;
        margin-bottom: 10px;

        .MuiAccordionSummary-content {
          // display: none;
          margin: 0;
        }
      }
    }

    .subscription, .promo {
      width: $full-width;
      display: flex;
      padding-bottom: 10px;
    }

    .options {
      width: $full-width;
      display: flex;
      flex-direction: column;

      .optionAlignment {
        display: flex;
        padding-bottom: 10px;
        align-items: center;
      }
    }
  }

  .totalPrice {
    width: $full-width;
    display: flex;
    border-top: 1px solid #e7e7e7;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .text {
    width: $full-width;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    .title {
      font-size: 16px;
    }

    .subTitleBox {
      display: flex;
      flex-direction: row;
      .subTitle {
        font-size: 13px;
        display: flex;
        align-items: center;
        color: #979696;
        white-space: break-spaces;
      }
    }
  }
  
  .price {
    width: $full-width;
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  .old-price {
    text-decoration: line-through;
  }
}
