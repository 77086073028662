//https://itnext.io/structuring-your-sass-projects-c8d41fa55ed4

/*Typo*/
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

/* abstract */
@import "abstracts/variables";
@import "abstracts/mixins";

//base

/* themes */
@import "theme/theme-config";

/* components */
// -> atoms
@import "components/atoms/Title/Title";
@import "components/atoms/Text/Text";
@import "components/atoms/StyledButton/StyledButton";
@import "components/atoms/LogoSvg/LogoSvg";
@import "components/atoms/InputText/InputText";
@import "components/atoms/SpinnerLoader/SpinnerLoader";
@import "components/atoms/Chip/Chip";

// -> molecules
@import "components/molecules/SubscriptionCard/SubscriptionCard";
@import "components/molecules/OptionCard/OptionCard";
@import "components/molecules/PaymentCard/PaymentCard";
@import "components/molecules/SubscriptionList/SubscriptionList";
@import "components/molecules/ModalInformationSubscription/ModalInformationSubscription";
@import "components/molecules/ModalSubscriptionGiftCard/ModalSubscriptionGiftCard";
@import "components/molecules/SubscriptionIncludes/SubscriptionIncludes";
@import "components/molecules/Basket/Basket";
@import "components/molecules/PaymentList/PaymentList";
@import "components/molecules/PaymentForm/PaymentForm";

// -> organisme
@import "components/organisms/SubscriptionChoice/SubscriptionChoice";
@import "components/organisms/OptionChoice/OptionChoice";
@import "components/organisms/PaymentChoice/PaymentChoice";
@import "components/organisms/SubscriptionCGV/SubscriptionCGV";

// -> pages
@import "components/pages/Subscription/Subscription";
@import "components/pages/Option/Option";
@import "components/pages/Payment/Payment";
@import "components/pages/Success/Success";
@import "components/pages/Fail/Fail";
@import "components/pages/Cancel/Cancel";
@import "components/pages/Unknown/Unknown";
@import "components/pages/Pending/Pending";

// -> templates
@import "components/templates/Header/Header";
@import "components/templates/Footer/Footer";

/* layout */

/* global */

body {
  @include themed($themes) {
    background: t("backgroundColor");
    color: t("text-color-primary");
  }
  font-family: "Lato";
  transition: all 0.5s linear;
  overflow-x: hidden;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  // margin-left: 50vw;
}

.App {
  @include themed($themes) {
    background: t("backgroundColor");
    color: t("text-color-primary");
  }
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  padding: 20px;
  overflow: scroll;
  flex: 1;
}

a {
  text-decoration: none;
}

.noPaddingTop {
  padding-top: 0 !important;
}

.alignCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: $full-width;
  height: $full-height;
}

.error, .error fieldset, .error label {
  color: red;
  border-color: red;
}
