.PaymentCard {
  margin-top: 10px;
  border: 1px solid $light-grey;
  // padding: 10px 15px;
  box-sizing: border-box;
  border-radius: $border-radius-m;
  display: flex;
  flex-direction: column;

  .Accordion {
    background-color: transparent;
    width: $full-width;
    border-radius: $border-radius-m !important;
    box-shadow: none;

    .Mui-expanded {
      min-height: 0px;
    }

    .MuiAccordionSummary-root {
      min-height: 0px;
      padding: 15px;

      .MuiAccordionSummary-content {
        margin: 0;

        .card {
          display: flex;
          flex-direction: row;
          width: $full-width;
          align-items: flex-end;

          .paymentChip {
            display: inherit;
            align-items: center;

            .LogoSvg {
              margin-bottom: 4px;
            }
          }

          .paymentTitle {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1;

            h1 {
              font-size: 16px !important;
            }
          }
        }
      }
    }

    .MuiCollapse-root {
      background-color: $white;
      border-top: 1px solid $primary;
      border-bottom-left-radius: $border-radius-m;
      border-bottom-right-radius: $border-radius-m;

      .MuiAccordionDetails-root {
        padding: 15px;
      }
    }
  }

  .bank {
    display: flex;
    flex-direction: row;
    width: $full-width;
    align-items: flex-end;
    padding: 15px;
    box-sizing: border-box;

    .paymentChip {
      display: inherit;
      align-items: center;

      .LogoSvg {
        margin-bottom: 4px;
      }
    }

    .paymentTitle {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1;

      h1 {
        font-size: 16px !important;
      }
    }
  }
}

.isPaymentCardActive {
  border: 1px solid $primary;
  background: #ed6e181a;
}
