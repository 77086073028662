.Cancel {
  display: flex;
  flex-direction: column;
  height: $full-height;
  padding: 20px;
  overflow: scroll;
  flex: 1;

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .Title {
      margin-top: 50px;
      h1 {
        font-size: 24px;
      }
    }

    .Text {
      margin-top: 20px;
      font-size: 13px;
      display: flex;
      align-items: center;
      color: #979696;
      white-space: break-spaces;
      p {
        font-size: 16px;
      }
    }
  }
}
