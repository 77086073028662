.SubscriptionCard {
  margin-top: 10px;
  width: $full-width;
  border: 1px solid $light-grey;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: $border-radius-m;
  display: flex;
  height: 90px;

  .subscriptionChip {
    display: inherit;
    align-items: center;
  }

  .subscriptionTitle {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;

    h1 {
      font-size: 16px !important;
      text-align: left;
    }
    
    h2 {
      font-size: 15px !important;
      color: $grey;
      text-align: left;
    }
  }

  .subscriptionPrice {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100px;

    .prices {
      display: flex;

      .picesColor {
        color: $primary !important;
        white-space: break-spaces;
      }
    }

    h1 {
      font-size: 16px !important;
    }

    p {
      font-size: 12px;
      color: $grey;
      text-align: left;
    }
  }
}

.isSubscriptionCardActive {
  border: 1px solid $primary;
  background: #ed6e181a;
}
