.Title {
  text-align: center;

  h1 {
    text-align: inherit;
    font-size: 18px;
    font-weight: 525;
    line-height: 140%;
  }

  h3 {
    text-align: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
  }
}
