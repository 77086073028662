.PaymentList {
  width: $full-width;

  .promoCode {
    margin-top: 25px;
    padding: 25px 0px;
    width: $full-width;
    border-top: 1px solid $light-grey;

    .promoCodeContainer {
      width: $full-width;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: $border-radius-m;
      border: 1px solid $light-grey;
      padding: 15px;
      box-sizing: border-box;

      h1 {
        font-size: 16px !important;
      }
    }
  }
}
