.ModalInformationSubscription {
  width: $full-width;
  position: fixed;
  bottom: 0;
  background-color: $white;
  min-height: 225px;
  border-radius: 8px 8px 0px 0px;

  .modalAlignement {
    padding: 10px;
    display: flex;
    flex-direction: column;

    .modalLogo {
      .MuiAvatar-root {
        background-color: #ed6e181a;

        svg {
          fill: $primary !important;
        }
      }
    }

    .modalTitle {
      margin-top: 10px;
      font-size: 16px;
    }

    .modalText {
      margin-top: 10px;
      font-size: 14px;
      padding: 0px 10px;
      color: $grey;
    }

    .closingCross {
      display: flex;
      justify-content: flex-end;
      width: $full-width;
      align-items: flex-end;
    }
  }
}
