.PaymentChoice {
  display: inherit;
  flex-direction: column;
  margin-top: 10px;

  .paymentSubtitleContainer {
    width: $full-width;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .paymentSubtitle {
      text-align: left;
    }

    .paymentTextSubtitle {
      margin: 10px 0px;
    }

    p {
      text-align: left;
      font-size: 14px;
      // font-weight: 300;
    }
  }
}
