.InputText {
  width: $full-width;

  // .MuiFormLabel-root {
  //   @include themed($themes) {
  //     color: t("text-color-primary");
  //   }
  // }

  // .MuiInputBase-input {
  //   @include themed($themes) {
  //     color: t("text-color-primary");
  //   }
  // }
  // .MuiOutlinedInput-notchedOutline {
  //   @include themed($themes) {
  //     border-color: t("text-color-primary");
  //   }
  // }
  // .Mui-focused fieldset {
  //   border-color: $primary !important;
  // }
}
