.ModalSubscriptionGiftCard {
  width: $full-width;
  position: fixed;
  bottom: 0;
  background-color: $white;
  min-height: 225px;
  border-radius: 8px 8px 0px 0px;

  .modalAlignement {
    padding: 15px;
    display: flex;
    flex-direction: column;

    .blocs {
      margin: 10px 0px;

      .Text {
        width: $full-width;
        display: flex;
        align-items: flex-start;
        font-size: 0.8rem;
      }
    }

    .closingCross {
      display: flex;
      justify-content: flex-end;
      width: $full-width;
      align-items: flex-end;
    }
  }
}
