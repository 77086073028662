.Option {
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: scroll;
  flex: 1;

  .button {
    margin-top: 25px;
  }
}
