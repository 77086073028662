.SubscriptionIncludes {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  .bloc {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 15px 0px;

    .logo {
      margin-right: 10px;
    }

    p {
      text-align: left !important;
      font-weight: 300;
    }

    .bold {
      font-weight: 500;
    }
  }

  .bloc:not(:last-child) {
    border-bottom: 1px solid $light-grey;
  }
}
