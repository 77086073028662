.OptionCard {
  margin-top: 10px;
  border: 1px solid $light-grey;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: $border-radius-m;
  display: flex;
  box-sizing: content-box;
  flex-direction: column;

  .offer {
    display: flex;
    width: $full-width;
    padding: 10px 0px;
    align-items: flex-end;

    .optionChip {
      display: inherit;
      align-items: center;

      .LogoSvg {
        margin-bottom: 4px;
      }
    }

    .optionTitle {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1;

      h1 {
        font-size: 16px !important;
      }
    }

    .optionPrice {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100px;

      .prices {
        display: flex;

        .picesColor {
          color: $primary !important;
          white-space: break-spaces;
        }
      }

      h1 {
        font-size: 16px !important;
      }

      p {
        font-size: 12px;
        color: $grey;
        text-align: left;
      }
    }
  }

  .text {
    display: flex;
    width: $full-width;
    border-top: 1px solid $light-grey;
    padding: 10px 0px;

    p {
      font-size: 12px;
      text-align: left;
      line-height: 18px;
    }
  }
}

.isOptionCardActive {
  border: 1px solid $primary;
  background: #ed6e181a;
}
