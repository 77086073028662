.Header {
  box-shadow: $primary-shadow;
  width: 100%;
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;

  .alignStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .test {
    color: $grey;
    font-size: 12px;
  }

  .alignEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .Logos {
    width: 24px;
    height: 24px;
  }
}
