.StyledButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $full-width;

  .MuiButtonBase-root {
    background: $primary-gradient;
    border-radius: 40px;
    width: $full-width;
    height: 45px;
    text-transform: none;
    box-shadow: none;

    &.Mui-disabled {
      background: #a6a6a6;
    }
  }
}
