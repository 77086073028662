.SubscriptionCGV {
  display: inherit;
  flex-direction: column;
  margin-top: 20px;

  .subscriptionSubtitle {
    text-align: left;
  }

  .textCGV {
    margin-top: 20px;

    .link {
      text-decoration: none;
      color: $primary;
    }

    p {
      color: $grey;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }
  }
}
