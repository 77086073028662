.SubscriptionChoice {
  display: inherit;
  flex-direction: column;
  margin-top: 10px;

  .subscriptionSubtitleContainer {
    width: $full-width;
    display: flex;

    .subscriptionSubtitle {
      text-align: left;
    }

    .subscriptionInfoLogo {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      svg {
        margin-bottom: 5px;
        margin-left: 10px;
      }
    }
  }
}
