.PaymentForm {
  .formAlignement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .inputAlignment {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
  }

  .hostedfield {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $light-grey;
    margin-bottom: 10px;

    [data-hipay-id="hostedfield-iframe"] {
      height: 25px;
      //   min-width: 0;
    }
  }
  .errorMessage {
    color: red;
  }
}
